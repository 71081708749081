<template>
  <div class="page">
    <div
      class="item"
      @click="toDetail(item)"
      v-for="(item, index) in list"
      :key="index"
    >
      <img :src="item.imgUrl" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "legalAdviceHome",
  data() {
    return {
      list: [
        {
          imgUrl: require("./img/guideBanner1.png"),
          msg: "",
          name: "lawServiceGuide",
          titleName: "法治全服务",
        },
        {
          imgUrl: require("./img/guideBanner2.png"),
          msg: 17,
          name: "legalAdvice",
          titleName: "法治微课堂",
        },
        {
          imgUrl: require("./img/guideBanner3.png"),
          msg: 18,
          name: "legalAdvice",
          titleName: "法治放映室",
        },
        {
          imgUrl: require("./img/guideBanner4.png"),
          msg: 19,
          name: "legalAdvice",
          titleName: "法治议事厅",
        },
        {
          imgUrl: require("./img/guideBanner5.png"),
          msg: 20,
          name: "legalAdvice",
          titleName: "法治活动角",
        },
      ],
    };
  },
  methods: {
    toDetail(item) {
      this.$router.push({
        name: item.name,
        query: {
          type: item.msg,
          titleName: item.titleName,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background: #fafafa;
  padding: 22px 30px 0;
  .item {
    width: 100%;
    margin-bottom: 20px;
    img {
      width: 100%;
    }
  }
}
</style>
